<template>
	<div id="ae-cookiebanner" class="ae-cookiebanner hidden">
		<div class="ae-background"></div>
		<router-view></router-view>
	</div>
</template>

<script>

export default {
	props: ['baseurl', 'color', 'lang'],
	created () {
		this.$router.push({ name: 'start' })
	},
	mounted () {
		this.$router.push({ name: 'start' })
		if (document.cookie.indexOf('ae-cookiebanner') < 0) {
			this.$el.className = this.$el.className.replace(/\bhidden\b/g, '');
			document.body.style.overflow = "hidden";
		} else {
			this.checkScripts()
		}
	},
	methods: {
		closeCookieBanner: function () {
			var cookiebanner = document.getElementById('ae-cookiebanner')
			cookiebanner.className += ' hidden'
			document.body.style.overflow = "auto";
		},
		setClosedCookie: function () {
			var d = new Date()
			d.setTime(d.getTime() + (90 * 24 * 60 * 60 * 1000))
			var expires = 'expires=' + d.toUTCString()
			document.cookie = 'ae-cookiebanner=true;' + expires + ';path=/'
		},
		setCatCookies: function (bEsentiells, bFunctionals, bStatistics) {
			var d = new Date()
			d.setTime(d.getTime() + (90 * 24 * 60 * 60 * 1000))
			var expires = 'expires=' + d.toUTCString()
			document.cookie = 'ae-esential=' + bEsentiells + ';' + expires + ';path=/'
			document.cookie = 'ae-functionals=' + bFunctionals + ';' + expires + ';path=/'
			document.cookie = 'ae-statistics=' + bStatistics + ';' + expires + ';path=/'
		},
		getCookie: function (sCookieName) {
			var name = sCookieName + '=';
			var decodedCookie = decodeURIComponent(document.cookie);
			var ca = decodedCookie.split(';');
			for(var i = 0; i <ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return '';
		},
		checkScripts: function () {
			var scripts = document.querySelectorAll('script');
			for (var i = 0; i < scripts.length; i++) {
				var script = scripts[i];
				if (script.hasAttribute('data-ae-cookiebanner-functional') && this.getCookie('ae-functionals') == 'true') {
					this.rewriteScript(script)
				} else if (script.hasAttribute('data-ae-cookiebanner-statistic') && this.getCookie('ae-statistics') == 'true') {
					this.rewriteScript(script)
				}
			}
		},
		rewriteScript: function (script) {
			var newScript = document.createElement('script');
			newScript.setAttribute('type', 'text/javascript');
			if (script.hasAttribute('src')) {
				newScript.setAttribute('src', script.src);
			}
			newScript.text = script.innerHTML;
			document.body.appendChild(newScript);
			script.parentNode.removeChild(script);
		},
		applySettingsOfUserSelection: function () {
			var bReload = false;
			if (document.cookie.indexOf('ae-cookiebanner') >= 0) {
				bReload = true;
			}
			this.setClosedCookie()
			
			var bFunctionalCookies = document.getElementById('ae-functionalvalue').checked;
			var bStatisticCookies = document.getElementById('ae-statisticlvalue').checked;
			
			this.setCatCookies(true, bFunctionalCookies, bStatisticCookies)
			this.checkScripts()
			this.closeCookieBanner()
			
			this.checkIfReloadIsRequired(bReload);
		},
		checkIfReloadIsRequired: function (bReload) {
			if (bReload) {
				location.reload();
			}
		},
		toggleFunctionalCookies: function (bInit) {
			var oCheckbox = document.getElementById('ae-functionalvalue');
			var oElement = document.getElementById('ae-functional-details');
			var oType = document.getElementById('type-functional');
			
			if (!bInit) {
				oCheckbox.checked = !oCheckbox.checked;
			}
			
			this.removeClass(oElement, 'active');
			this.removeClass(oType, 'active');
			if (oCheckbox.checked) {
				this.addClass(oElement, 'active');
				this.addClass(oType, 'active');
			}
			if (document.getElementById('ae-functionalvalue').checked && document.getElementById('ae-statisticlvalue').checked) {
				this.addClass(document.getElementById('ae-selectall'), 'allselected');
			} else {
				this.removeClass(document.getElementById('ae-selectall'), 'allselected');
			}
		},
		toggleStatisticCookies: function (bInit) {
			var oCheckbox = document.getElementById('ae-statisticlvalue');
			var oElement = document.getElementById('ae-statistics-details');
			var oType = document.getElementById('type-statistic');
			
			if (!bInit) {
				oCheckbox.checked = !oCheckbox.checked;
			}
			
			this.removeClass(oElement, 'active');
			this.removeClass(oType, 'active');
			if (oCheckbox.checked) {
				this.addClass(oElement, 'active');
				this.addClass(oType, 'active');
			}
			if (document.getElementById('ae-functionalvalue').checked && document.getElementById('ae-statisticlvalue').checked) {
				this.addClass(document.getElementById('ae-selectall'), 'allselected');
			} else {
				this.removeClass(document.getElementById('ae-selectall'), 'allselected');
			}
		},
		hasClass: function(el, className)
		{
			if (el.classList)
				return el.classList.contains(className);
			return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
		},
		addClass: function(el, className)
		{
			if (el.classList)
				el.classList.add(className)
			else if (!hasClass(el, className))
				el.className += " " + className;
		},
		removeClass: function(el, className)
		{
			if (el.classList)
				el.classList.remove(className)
			else if (hasClass(el, className))
			{
				var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
				el.className = el.className.replace(reg, ' ');
			}
		}
	}
}
</script>
