<template>
	<div class="ae-view-category">
		<div class="ae-innercategory">
			<div class="ae-headlinecontainer">
				<div class="ae-heading" v-text="settingsdetail_owner"></div>
				<div class="ae-value">{{ category.owner }}</div>
			</div>
			<div class="ae-listcont" v-if="category.privacylink">
				<div class="ae-heading" v-text="settingsdetail_privacytext"></div>
				<div class="ae-value">
					<a :href="`${category.privacylink}`" target="_blank">
						{{ category.privacylink }}
						<svg xmlns="http://www.w3.org/2000/svg" width="16.903" height="16.903" viewBox="0 0 16.903 16.903">
							<g id="external-link" transform="translate(-2.5 -2.5)">
								<path id="Pfad_5266" data-name="Pfad 5266" d="M16.252,12.718v4.417A1.767,1.767,0,0,1,14.485,18.9H4.767A1.767,1.767,0,0,1,3,17.136V7.417A1.772,1.772,0,0,1,4.767,5.65H9.184M13.6,3h5.3V8.3M9.184,12.718,18.2,3.707" transform="translate(0 0)" fill="none" stroke="#001441" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" fill-rule="evenodd"/>
							</g>
						</svg>
					</a>
				</div>
			</div>
			<div class="ae-listcont">
				<div class="ae-heading" v-text="settingsdetail_cookiename"></div>
				<div class="ae-value">{{ category.cookielist }}</div>
			</div>
			<div class="ae-listcont">
				<div class="ae-heading" v-text="settingsdetail_cookiexpire"></div>
				<div class="ae-value">{{ category.cookieduration }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		category: null
	},
	data: function () {
		return {
			'settingsdetail_owner': '',
			'settingsdetail_privacytext': '',
			'settingsdetail_cookiename': '',
			'settingsdetail_cookiexpire': '',
		}
	},
	created () {
		if (this.$parent.$parent.lang == 'DE') {
			this.settingsdetail_owner = 'Anbieter';
			this.settingsdetail_privacytext = 'Datenschutzrichtlinien des Anbieters';
			this.settingsdetail_cookiename = 'Cookie-Namen';
			this.settingsdetail_cookiexpire = 'Cookie-Laufzeit';
		} else if (this.$parent.$parent.lang == 'IT') {
			this.settingsdetail_owner = 'Fornitore';
			this.settingsdetail_privacytext = 'Direttive privacy del fornitore';
			this.settingsdetail_cookiename = 'Nome cookie';
			this.settingsdetail_cookiexpire = 'Durata cookie';
		} else {
			this.settingsdetail_owner = 'Provider';
			this.settingsdetail_privacytext = 'Privacy policy of the provider';
			this.settingsdetail_cookiename = 'Cookie name';
			this.settingsdetail_cookiexpire = 'Cookie duration';
		}
	},
}
</script>
