<template>
	<div class="ae-view-settings-container">

		<AESettings></AESettings>

		<div class="ae-view-settings-detail hidden" id="ae-esentiell-details">
			<div class="ae-inner">
				<div class="ae-headline ae-fs-big" v-text="settings_esentiell_headline2"/>
				<div class="ae-text" v-text="settingsdetail_esentiell_text"/>
				<div class="ae-checkbox">
					<svg class="selected" xmlns="http://www.w3.org/2000/svg" width="21.414" height="20" viewBox="0 0 21.414 20">
						<g id="Gruppe_4165" data-name="Gruppe 4165" transform="translate(-482 -345)">
							<path id="Pfad_5261" data-name="Pfad 5261" d="M9,11l3,3L22,4" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
							<path id="Pfad_5262" data-name="Pfad 5262" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
						</g>
					</svg>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
						<path id="Pfad_5263" data-name="Pfad 5263" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(22 22) rotate(180)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
					</svg>
					<div class="ae-catheadline" v-text="settings_esentiell_headline"/>
				</div>
				<div class="ae-categorylist">
					<AECategory :category="category" v-for="category in categories.esentiell"></AECategory>
				</div>
			</div>
			<div class="ae-savebutton">
				<a class="ae-button" v-on:click="applySettingsOfUserSelection();" :style="{'background-color':$root.color}" v-text="settingsdetail_saveandclose" />
			</div>
		</div>
		
		<div class="ae-view-settings-detail hidden" id="ae-functional-details">
			<div class="ae-inner">
				<div class="ae-headline ae-fs-big" v-text="settings_functional_headline2"/>
				<div class="ae-text" v-text="settingsdetail_functional_text"/>
				<div class="ae-checkbox">
					<a v-on:click="toggleFunctionalCookies();">
						<svg class="selected" xmlns="http://www.w3.org/2000/svg" width="21.414" height="20" viewBox="0 0 21.414 20">
							<g id="Gruppe_4165" data-name="Gruppe 4165" transform="translate(-482 -345)">
								<path id="Pfad_5261" data-name="Pfad 5261" d="M9,11l3,3L22,4" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
								<path id="Pfad_5262" data-name="Pfad 5262" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
							</g>
						</svg>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
							<path id="Pfad_5263" data-name="Pfad 5263" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(22 22) rotate(180)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
						</svg>
					</a>
					<div class="ae-catheadline" v-text="settings_functional_headline"/>
				</div>
				<div class="ae-categorylist">
					<AECategory :category="category" v-for="category in categories.functional"></AECategory>
				</div>
			</div>
			<div class="ae-savebutton">
				<a class="ae-button" v-on:click="applySettingsOfUserSelection();" :style="{'background-color':$root.color}" v-text="settingsdetail_saveandclose" />
			</div>
		</div>
		
		<div class="ae-view-settings-detail hidden" id="ae-statistics-details">
			<div class="ae-inner">
				<div class="ae-headline ae-fs-big" v-text="settings_statistic_headline2"/>
				<div class="ae-text" v-text="settingsdetail_statistic_text"/>
				<div class="ae-checkbox">
					<a v-on:click="toggleStatisticCookies();">
						<svg class="selected" xmlns="http://www.w3.org/2000/svg" width="21.414" height="20" viewBox="0 0 21.414 20">
							<g id="Gruppe_4165" data-name="Gruppe 4165" transform="translate(-482 -345)">
								<path id="Pfad_5261" data-name="Pfad 5261" d="M9,11l3,3L22,4" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
								<path id="Pfad_5262" data-name="Pfad 5262" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
							</g>
						</svg>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
							<path id="Pfad_5263" data-name="Pfad 5263" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(22 22) rotate(180)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
						</svg>
					</a>
					<div class="ae-catheadline" v-text="settings_statistic_headline"/>
				</div>
				<div class="ae-categorylist">
					<AECategory :category="category" v-for="category in categories.statistic"></AECategory>
				</div>
			</div>
			<div class="ae-savebutton">
				<a class="ae-button" v-on:click="applySettingsOfUserSelection();" :style="{'background-color':$root.color}" v-text="settingsdetail_saveandclose" />
			</div>
		</div>
	</div>
</template>

<script>

import AESettings from '../components/AESettings'
import AECategory from '../components/AECategory'

export default {
	created () {
		if (this.$parent.lang == 'DE') {
			this.settings_esentiell_headline2 = 'Essentielle Cookies: Diese Cookies benötigen wir zwingend.';
			this.settingsdetail_esentiell_text = 'Essentielle Cookies benötigen wir, damit unsere Website reibungslos funktioniert, z.B. für die Spracheinstellung, die Navigation, und damit wir alle Elemente korrekt darstellen können. Ohne die essentiellen Cookies können Sie unsere Website nicht besuchen.';
			this.settings_esentiell_headline = 'Essentielle Cookies';
			this.settingsdetail_saveandclose = 'Speichern und schließen';
			this.settings_functional_headline2 = 'Funktionelle Cookies: Damit die Tools unserer Partner auch funktionieren.';
			this.settingsdetail_functional_text = 'Wir benötigen funktionelle Cookies, um die Integrationen externer Tools/Widgets (z.B. YouTube-Videos) korrekt darstellen zu können. Außerdem können wir anhand der funktionellen Cookies anonymisiert erheben, wie sich die Besucher durch unsere Website bewegen.';
			this.settings_functional_headline = 'Funktionelle Cookies';
			this.settings_statistic_headline2 = 'Marketing-Cookies: Damit wir verstehen, was wir besser machen können.';
			this.settingsdetail_statistic_text = 'Marketing-Cookies helfen uns, Sie auf unserer Website wiederzuerkennnen.Wir erfassen damit anonymisierte Daten für Statistiken, um zu verstehen, wie erfolgreich unsere Werbung ist. Zudem können wir Ihren Webseitenbesuch für Sie relevanter gestalten und mit auf Sie zugeschnittenen Inhalten versehen.';
			this.settings_statistic_headline = 'Marketing Cookies';
			this.session = 'Session';
			this.jahre = 'Jahre';
			this.jahr = 'Jahr';
			this.stunden = 'Stunden';
			this.minuten = 'Minuten';
			this.minute = 'Minute';
			this.tage = 'Tage';
			this.monate = 'Monate';
		} else if (this.$parent.lang == 'IT') {
			this.settings_esentiell_headline2 = 'Cookie essenziali: Questi cookie sono indispensabili.';
			this.settingsdetail_esentiell_text = 'I cookie essenziali servono a far funzionare il sito web senza problemi, p. es. per le impostazioni di lingua, la navigazione, e per far si che tutti gli elementi vengano visualizzati correttamente. Senza i cookie essenziali non è possibile accedere al nostro sito.';
			this.settings_esentiell_headline = 'Cookie essenziali';
			this.settingsdetail_saveandclose = 'Salva e chiudi';
			this.settings_functional_headline2 = 'Cookie funzionali: per garantire il funzionamento degli strumenti dei nostri partner';
			this.settingsdetail_functional_text = 'I cookie funzionali ci aiutano a visualizzare correttamente strumenti/widget esterni (p.es. video YouTube). Inoltre, grazie ai cookie funzionali possiamo rilevare, in modo anonimizzato, come gli utenti navigano sul nostro sito.';
			this.settings_functional_headline = 'Cookie funzionali';
			this.settings_statistic_headline2 = 'Cookie marketing: per capire dove possiamo migliorare.';
			this.settingsdetail_statistic_text = 'I cookie marketing ci aiutano a riconoscere gli utenti del nostro sito. Con essi registriamo dati anonimizzati per scopi statistici, al fine di valutare la prestazione della nostra pubblicità. Inoltre, possiamo rendere più rilevante la vostra esperienza sul sito e fornirvi contenuti su misura per le vostre esigenze.';
			this.settings_statistic_headline = 'Cookie marketing';
			this.session = 'sessione';
			this.jahre = 'anni';
			this.jahr = 'anno';
			this.minuten = 'minuti';
			this.minute = 'minuto';
			this.stunden = 'ore';
			this.tage = 'giorni';
			this.monate = 'mesi';
		} else {
			this.settings_esentiell_headline2 = 'Essential cookies: we absolutely need these cookies.';
			this.settingsdetail_esentiell_text = 'We need essential cookies to keep our website running smoothly, i.e., for language settings, navigation, and to display all elements correctly. Without essential cookies, you cannot open our website.';
			this.settings_esentiell_headline = 'Essential cookies';
			this.settingsdetail_saveandclose = 'Save and close';
			this.settings_functional_headline2 = 'Functional cookies: to make our partners\' tools work.';
			this.settingsdetail_functional_text = 'Functional cookies ensure that the integrations of external tools/widgets (i.e., YouTube videos) are displayed correctly. Through functional cookies, we also can collect anonymized data regarding the user behavior on our website.';
			this.settings_functional_headline = 'Functional cookies';
			this.settings_statistic_headline2 = 'Marketing cookies: to understand where we can do better.';
			this.settingsdetail_statistic_text = 'Marketing cookies help us to recognize you on our website. Through them, we collect anonymized data for statistical purposes in order to evaluate the performance of our ad campaigns. In doing so, we can provide a more relevant user experience and offer tailored content to you.';
			this.settings_statistic_headline = 'Marketing cookies';
			this.session = 'session';
			this.jahre = 'years';
			this.jahr = 'year';
			this.minuten = 'minutes';
			this.minute = 'minute';
			this.stunden = 'hours';
			this.tage = 'days';
			this.monate = 'months';
		}
		
		this.categories = {
			esentiell: {
				aewebdesign: {
					owner: "AE Webdesign",
					privacylink: "",
					cookielist: "ae-cookiebanner, ae-statistics, ae-functionals, ae-esential, kirby_session, wp-wpml_current_language, _icl_visitor_lang_js, wpml_browser_redirect_test",
					cookieduration: this.session + " - 2 " + this.jahre
				},
				recaptcha: {
					owner: "Google reCAPTCHA",
					privacylink: "https://policies.google.com/privacy",
					cookielist: "IDE, 1P_JAR, ANID, CONSENT, NID, DV",
					cookieduration: "10 " + this.minuten + " - 19 " + this.jahre
				}
			},
			functional: {
				youtube: {
					owner: "YouTube",
					privacylink: "https://www.youtube.com/static?template=terms",
					cookielist: "APISID, CONSENT, HSID, LOGIN_INFO, PREF, SAPISID, SID, SIDCC, SSID, VISITOR_INFO1_LIVE, YSC, 1P_JAR, DV, NID, OTZ, SEARCH_SAMESITE",
					cookieduration: this.session + " - 179 " + this.tage
				},
				vimeo: {
					owner: "Vimeo",
					privacylink: "https://vimeo.com/privacy",
					cookielist: "__qca__ssid, _ga, _gcl_au, _gid, continuous_play_v3, player, vuid",
					cookieduration: "24 " + this.stunden + " - 2 " + this.jahre
				},
				googlemaps: {
					owner: "Google Maps",
					privacylink: "https://policies.google.com/privacy",
					cookielist: "1P_JAR, APISID, CONSENT, DV, HSID, NID, OTZ, SAPISID, SEARCH_SAMESITE, SID, SIDCC, SSID",
					cookieduration: this.session + " - 2 " + this.jahre
				},
				facebook: {
					owner: "Facebook",
					privacylink: "https://www.facebook.com/policy.php",
					cookielist: "c_user, datr, fr, sb, wd, xs",
					cookieduration: this.session + " - 2 " + this.jahre
				},
				outdooractive: {
					owner: "Outdoor-Active",
					privacylink: "https://www.outdooractive.com/de/privacy.html#gref",
					cookielist: "_gcl_au, __utmz, __utma, JSESSIONID , _ga, oa_session_at, _bCookieMsgShown",
					cookieduration: this.session + " - 2 " + this.jahre
				},
				mapbox: {
					owner: "Mapbox",
					privacylink: "https://www.mapbox.com/legal/privacy",
					cookielist: "_ga, _mkto_trk, ajs_anonymous_id, ajs_group_id, ajs_user_id",
					cookieduration: this.session + " - 2 " + this.jahre
				},
				juicer: {
					owner: "Juicer",
					privacylink: "https://www.mapbox.com/legal/privacy",
					cookielist: "__cfduid, _ga, _gat, _gid, preferred_feed ",
					cookieduration: this.session + " - 24 " + this.stunden
				},
				hotjar: {
					owner: "Hotjar",
					privacylink: "https://www.hotjar.com/legal/policies/privacy/",
					cookielist: "_hjid, _hjClosedSurveyInvites, _hjDonePolls, _hjMinimizedPolls, _hjDoneTestersWidgets, _hjMinimizedTestersWidgets, _hjIncludedInSample, _hjShownFeedbackMessage",
					cookieduration: "1 " + this.jahr
				}
			},
			statistic: {
				googleanalytics: {
					owner: "Google Analytics & Tag Manager",
					privacylink: "https://policies.google.com/privacy",
					cookielist: "_ga, _gid, _gat, AMP_TOKEN, _gac_<property-id>, __utma, __utmt, __utmb, __utmz, __utmv, __utmx, __utmxx, _gaexp, _opt_awcid, _opt_awmid, _opt_awgid, _opt_awkid, _opt_utmc",
					cookieduration: "1 " + this.minute + " - 2 " + this.jahre
				},
				facebook: {
					owner: "Facebook",
					privacylink: "https://www.facebook.com/policy.php",
					cookielist: "act, c_user, datr, fr, player, dpr, presence, sb, wd, xs",
					cookieduration: this.session + " - 2 " + this.jahre
				},
				matomo: {
					owner: "Matomo",
					privacylink: "https://matomo.org/privacy-policy/",
					cookielist: "_pk_id, _pk_ref, _pk_ses, _pk_cvar, _pk_hsr, _pk_testcookie, mtm_consent, mtm_consent_removed, mtm_cookie_consent",
					cookieduration: "30 " + this.minuten + " - 13 " + this.monate
				}
			}
		}
		
	},
	data () {
		return {
			'settings_esentiell_headline2': '',
			'settingsdetail_esentiell_text': '',
			'settings_esentiell_headline': '',
			'settingsdetail_saveandclose': '',
			'settings_functional_headline2': '',
			'settingsdetail_functional_text': '',
			'settings_functional_headline': '',
			'settings_statistic_headline2': '',
			'settingsdetail_statistic_text': '',
			'settings_statistic_headline': '',
			'session': '',
			'jahre': '',
			'minuten': '',
			categories: ''
		}
	},
	components: {
		AESettings,
		AECategory
	},
	methods: {
		mounted: function () {
			this.$parent.toggleFunctionalCookies(true);
			this.$parent.toggleStatisticCookies(true);
		},
		applySettingsOfUserSelection: function () {
			var otherDetails = document.getElementById('ae-functional-details')
			this.$parent.addClass(otherDetails, 'hidden');
			var otherDetails = document.getElementById('ae-esentiell-details')
			this.$parent.addClass(otherDetails, 'hidden');
			var otherDetails = document.getElementById('ae-statistics-details')
			this.$parent.addClass(otherDetails, 'hidden');
		},
		toggleFunctionalCookies: function () {
			this.$parent.toggleFunctionalCookies(false);
		},
		toggleStatisticCookies: function () {
			this.$parent.toggleStatisticCookies(false);
		}
	}
}
</script>
