<template>
	<div class="ae-view-settings">
		<div class="ae-inner">
			<div class="ae-headline ae-fs-medium ae-flex ae-jcsb">
				<span v-text="settings_headline" />
				<div class="ae-closeicon pointer" v-on:click="applySettingsOfUserSelection();">
					<img v-bind:src="$root.baseurl + 'images/icon-close.svg'" alt="">
				</div>
			</div>
			<div class="ae-selectall ae-flex ae-jcsb" id="ae-selectall">
				<span v-text="settings_selectall" />
				<div class="ae-closeicon pointer">
					<a v-on:click="selectAllCategories();">
					<svg xmlns="http://www.w3.org/2000/svg" width="21.414" height="20" viewBox="0 0 21.414 20" class="selected">
						<g id="Gruppe_4165" data-name="Gruppe 4165" transform="translate(-482 -345)">
							<path id="Pfad_5261" data-name="Pfad 5261" d="M9,11l3,3L22,4" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
							<path id="Pfad_5262" data-name="Pfad 5262" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
						</g>
					</svg>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
								<path id="Pfad_5263" data-name="Pfad 5263" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(22 22) rotate(180)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
							</svg>
					</a>
				</div>
			</div>
			<div class="ae-typelist">
				<div class="ae-type ae-flex ae-jcsb ae-aic">
					<div class="ae-text" v-text="settings_esentiell_text" />
					<div class="ae-icons ae-flex ae-jcsb">
						<a v-on:click="openEsentiellDetails();"><img v-bind:src="$root.baseurl + 'images/icon-info.svg'" alt=""></a>
						<a class="nopointer">
							<svg class="selected" xmlns="http://www.w3.org/2000/svg" width="21.414" height="20" viewBox="0 0 21.414 20">
								<g id="Gruppe_4165" data-name="Gruppe 4165" transform="translate(-482 -345)">
									<path id="Pfad_5261" data-name="Pfad 5261" d="M9,11l3,3L22,4" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
									<path id="Pfad_5262" data-name="Pfad 5262" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
								</g>
							</svg>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
								<path id="Pfad_5263" data-name="Pfad 5263" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(22 22) rotate(180)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
							</svg>
						</a>
					</div>
				</div>
				<div class="ae-type ae-flex ae-jcsb ae-aic" id="type-functional">
					<div class="ae-text" v-text="settings_functional_text" />
					<div class="ae-icons ae-flex ae-jcsb">
						<a v-on:click="openFunctionalDetails();"><img v-bind:src="$root.baseurl + 'images/icon-info.svg'" alt=""></a>
						<a v-on:click="toggleFunctionalCookies();">
							<svg class="selected" xmlns="http://www.w3.org/2000/svg" width="21.414" height="20" viewBox="0 0 21.414 20">
								<g id="Gruppe_4165" data-name="Gruppe 4165" transform="translate(-482 -345)">
									<path id="Pfad_5261" data-name="Pfad 5261" d="M9,11l3,3L22,4" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
									<path id="Pfad_5262" data-name="Pfad 5262" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
								</g>
							</svg>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
								<path id="Pfad_5263" data-name="Pfad 5263" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(22 22) rotate(180)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
							</svg>
							<input type="checkbox" id="ae-functionalvalue">
						</a>
					</div>
				</div>
				<div class="ae-type ae-flex ae-jcsb ae-aic" id="type-statistic">
					<div class="ae-text" v-text="settings_statistic_text" />
					<div class="ae-icons ae-flex ae-jcsb">
						<a v-on:click="openStatisticDetails();"><img v-bind:src="$root.baseurl + 'images/icon-info.svg'" alt=""></a>
						<a v-on:click="toggleStatisticCookies();">
							<svg class="selected" xmlns="http://www.w3.org/2000/svg" width="21.414" height="20" viewBox="0 0 21.414 20">
								<g id="Gruppe_4165" data-name="Gruppe 4165" transform="translate(-482 -345)">
									<path id="Pfad_5261" data-name="Pfad 5261" d="M9,11l3,3L22,4" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
									<path id="Pfad_5262" data-name="Pfad 5262" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(480 343)" fill="none" :stroke="$root.color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
								</g>
							</svg>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
								<path id="Pfad_5263" data-name="Pfad 5263" d="M21,12v7a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V5A2,2,0,0,1,5,3H16" transform="translate(22 22) rotate(180)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
							</svg>
							<input type="checkbox" id="ae-statisticlvalue">
						</a>
					</div>
				</div>
			</div>
			<div class="ae-savebutton">
				<a class="ae-button" v-on:click="applySettingsOfUserSelection();" :style="{'background-color':$root.color}" v-text="settings_savesettings" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			'settings_headline': '',
			'settings_selectall': '',
			'settings_esentiell_text': '',
			'settings_functional_text': '',
			'settings_statistic_text': '',
			'settings_savesettings': ''
		}
	},
	created () {
		if (this.$parent.$parent.lang == 'DE') {
			this.settings_headline = 'Cookie-Einstellungen wählen.';
			this.settings_selectall = 'Alle Cookies auswählen.';
			this.settings_esentiell_text = 'Essentielle Cookies benötigen wir, damit unsere Website reibungslos funktioniert.';
			this.settings_functional_text = 'Funktionelle Cookies ermöglichen eine korrekte Darstellung von externen Tools/Inhalten (z.B. YouTube-Videos).';
			this.settings_statistic_text = 'Marketing-Cookies helfen uns, das Verhalten der Websitebesucher zu analysieren.';
			this.settings_savesettings = 'Einstellungen speichern';
		} else if (this.$parent.$parent.lang == 'IT') {
			this.settings_headline = 'Gestisci cookie.';
			this.settings_selectall = 'Accetta tutti i cookie.';
			this.settings_esentiell_text = 'I cookie essenziali servono a far funzionare il sito web senza problemi.';
			this.settings_functional_text = 'I cookie funzionali servono a visualizzare correttamente strumenti/contenuti esterni (p.es. video YouTube).';
			this.settings_statistic_text = 'I cookie marketing servono ad analizzare il comportamento dell\'utente sul sito.';
			this.settings_savesettings = 'Salva impostazioni';
		} else {
			this.settings_headline = 'Cookie settings.';
			this.settings_selectall = 'Allow all cookies.';
			this.settings_esentiell_text = 'We need essential cookies to keep our website running smoothly.';
			this.settings_functional_text = 'Functional cookies ensure the correct display of external tools / content (i.e., YouTube videos).';
			this.settings_statistic_text = 'We need marketing cookies to analyze the user behavior on our website.';
			this.settings_savesettings = 'Save settings';
		}
	},
	mounted () {
		var oCheckbox = document.getElementById('ae-functionalvalue')
		oCheckbox.checked = (this.$parent.$parent.getCookie('ae-functionals') == 'true');
		var oCheckbox = document.getElementById('ae-statisticlvalue')
		oCheckbox.checked = (this.$parent.$parent.getCookie('ae-statistics') == 'true');
		this.$parent.$parent.toggleFunctionalCookies(true);
		this.$parent.$parent.toggleStatisticCookies(true);
	},
	methods: {
		openEsentiellDetails: function () {
			var otherDetails = document.getElementById('ae-functional-details');
			this.$parent.$parent.addClass(otherDetails, 'hidden');
			var otherDetails = document.getElementById('ae-statistics-details');
			this.$parent.$parent.addClass(otherDetails, 'hidden');
			var otherDetails = document.getElementById('ae-esentiell-details');
			this.$parent.$parent.removeClass(otherDetails, 'hidden');
		},
		openFunctionalDetails: function () {
			var otherDetails = document.getElementById('ae-esentiell-details');
			this.$parent.$parent.addClass(otherDetails, 'hidden');
			var otherDetails = document.getElementById('ae-statistics-details');
			this.$parent.$parent.addClass(otherDetails, 'hidden');
			var otherDetails = document.getElementById('ae-functional-details');
			this.$parent.$parent.removeClass(otherDetails, 'hidden');
		},
		openStatisticDetails: function () {
			var otherDetails = document.getElementById('ae-functional-details');
			this.$parent.$parent.addClass(otherDetails, 'hidden');
			var otherDetails = document.getElementById('ae-esentiell-details');
			this.$parent.$parent.addClass(otherDetails, 'hidden');
			var otherDetails = document.getElementById('ae-statistics-details');
			this.$parent.$parent.removeClass(otherDetails, 'hidden');
		},
		applySettingsOfUserSelection: function () {
			this.$parent.$parent.applySettingsOfUserSelection();
		},
		toggleFunctionalCookies: function () {
			this.$parent.$parent.toggleFunctionalCookies(false);
			if (!document.getElementById('ae-functionalvalue').checked) {
				this.$parent.$parent.removeClass(document.getElementById('ae-selectall'), 'allselected');
			}
			if (document.getElementById('ae-functionalvalue').checked && document.getElementById('ae-statisticlvalue').checked) {
				this.$parent.$parent.addClass(document.getElementById('ae-selectall'), 'allselected');
			}
		},
		toggleStatisticCookies: function () {
			this.$parent.$parent.toggleStatisticCookies(false);
			if (!document.getElementById('ae-statisticlvalue').checked) {
				this.$parent.$parent.removeClass(document.getElementById('ae-selectall'), 'allselected');
			}
			if (document.getElementById('ae-functionalvalue').checked && document.getElementById('ae-statisticlvalue').checked) {
				this.$parent.$parent.addClass(document.getElementById('ae-selectall'), 'allselected');
			}
		},
		selectAllCategories: function () {
			if (document.getElementById('ae-functionalvalue').checked && document.getElementById('ae-statisticlvalue').checked) {
				document.getElementById('ae-functionalvalue').checked = false;
				this.$parent.$parent.toggleFunctionalCookies(true);
				document.getElementById('ae-statisticlvalue').checked = false;
				this.$parent.$parent.toggleStatisticCookies(true);
				this.$parent.$parent.removeClass(document.getElementById('ae-selectall'), 'allselected');
			} else {
				document.getElementById('ae-functionalvalue').checked = true;
				this.$parent.$parent.toggleFunctionalCookies(true);
				document.getElementById('ae-statisticlvalue').checked = true;
				this.$parent.$parent.toggleStatisticCookies(true);
				this.$parent.$parent.addClass(document.getElementById('ae-selectall'), 'allselected');
			}
			
		}
	}
}
</script>
