import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './assets/css/main.scss'

import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

Vue.config.productionTip = false
App.router = router
Vue.customElement('vue-widget', App)
